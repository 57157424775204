<template>
  <div class="px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Technographics</b> - <i>Solution Report</i>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <v-wait for="fetchingReports">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="10"></content-placeholders-text>
          </content-placeholders>
        </template>
        <b-row class="page-sub-header">
          <b-col cols="6">
            <h5>{{ service.name }}</h5>
          </b-col>

          <b-col cols="6" class="text-right">
            <h6>
              Click on a Vendor Solution for a list of financial institutions
            </h6>
          </b-col>
        </b-row>

        <hr class="mt-0" />

        <b-card
          v-if="vendor_products.length === 0"
          class="text-center empty-result"
        >
          No Records Found
        </b-card>

        <b-card-group columns>
          <b-card
            v-for="vendor_product in this.vendor_products"
            :key="vendor_product.id"
            class="std-border reports-card mb-4"
            body-class="p-1"
            header-class="pl-3"
          >
            <div slot="header">
              <h6
                class="mb-0 vendor-card"
                @click="redirectToVendor(vendor_product.id)"
              >
                <b>{{ vendor_product.name }}</b>
              </h6>
            </div>

            <b-list-group class="selectable-list-group">
              <router-link
                v-for="product in vendor_product.products"
                :key="product.id"
                class="list-group-item list-group-item-action p-2"
                :to="{
                  path: '/technographics/product_report/' + product.id,
                  query: {
                    vendor_id: vendor_product.id,
                    bank_service_id: service.id
                  }
                }"
              >
                {{ product.name }}
              </router-link>
            </b-list-group>
          </b-card>
        </b-card-group>
      </v-wait>
    </b-card>
  </div>
</template>

<script>
import router from '@/router'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$wait.start('fetchingReports')
      vm.$http
        .get('/api/bank_services/' + vm.$route.params.id, {
          params: { service_type: 'technographics' },
          handleErrors: true
        })
        .then(response => {
          vm.vendor_products = response.data.vendor_products
          vm.service = response.data.service
          document.title = 'FIN Technographics'
          vm.$wait.end('fetchingReports')
        })
    })
  },
  data() {
    return {
      vendor_products: [],
      service: ''
    }
  },
  methods: {
    redirectToVendor(id) {
      router.push('/technographics/vendor_report/' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.reports-card {
  .card-body {
    max-height: 20rem;
    overflow: auto;

    .list-group-item {
      position: static;
    }
  }
}
.vendor-card {
  cursor: pointer;
}
</style>
